import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import React from "react";

const ScreenTitle = (props) => (
  <Box mt={5} mb={5}>
    <Typography
      variant={"h4"}
      fontSize={40}
      fontFamily={"Rubik"}
      fontWeight={800}
      color={"primary"}
    >
      {props.title}
    </Typography>
    <Divider />
  </Box>
);

export default ScreenTitle;
