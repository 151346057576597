import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { GlobalStore } from "../../stores/globalContext";
import { useSearchParams } from "react-router-dom";

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const stores = useContext(GlobalStore);
  let [, setSearchParams] = useSearchParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = async () => {
    setSearchParams((searchParams) => {
      searchParams.set("edit", "false");
      return searchParams;
    });
    handleClose();
    await stores.member.handleFormSubmit();
  };

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        style={{ paddingLeft: 50, paddingRight: 50 }}
        onClick={handleClickOpen}
      >
        Submit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Completed By:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your full name to complete the Rebate Form submission.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Full Name"
            type="Name"
            fullWidth
            variant="standard"
            value={
              stores.member.scanRebateData.completedBy
                ? stores.member.scanRebateData.completedBy
                : ""
            }
            onChange={stores.member.completedBy}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              stores.member.scanRebateData.completedBy &&
              stores.member.scanRebateData.completedBy.length < 3
            }
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
