import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

export const AuthenticationGuard = ({ component }) => {
  const { banner } = useParams();
  const Component = withAuthenticationRequired(component, {
    returnTo: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}/${banner}/all-forms`,
    onRedirecting: () => (
      <div className="page-layout">
        <CircularProgress />
      </div>
    ),
  });

  return <Component />;
};
