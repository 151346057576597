export const FormStatus = (status) => {
  let stateFormStatusBgcolor;
  let stateFormStatusFrontColor;

  switch (status) {
    case "Closed":
      stateFormStatusBgcolor = "#333333";
      stateFormStatusFrontColor = "white";
      break;
    case "N/A":
      stateFormStatusBgcolor = "#F2F2F2";
      stateFormStatusFrontColor = "#000000";
      break;
    case "Available":
      stateFormStatusBgcolor = "#FFDD0A";
      stateFormStatusFrontColor = "#000000";
      break;
    case "Reviewed":
      stateFormStatusBgcolor = "#1BB346";
      stateFormStatusFrontColor = "white";
      break;
    case "Overdue":
      stateFormStatusBgcolor = "#B31919";
      stateFormStatusFrontColor = "white";
      break;
    case "Submitted":
      stateFormStatusBgcolor = "#1BB346";
      stateFormStatusFrontColor = "white";
      break;
    case "Submitted - Wave 1":
      stateFormStatusBgcolor = "#1BB346";
      stateFormStatusFrontColor = "white";
      break;
    case "Submitted - Wave 2":
      stateFormStatusBgcolor = "#1BB346";
      stateFormStatusFrontColor = "white";
      break;
    case "Not Submitted":
      stateFormStatusBgcolor = "#B31919";
      stateFormStatusFrontColor = "white";
      break;
    case "Closed - Wave 1":
      stateFormStatusBgcolor = "#333333";
      stateFormStatusFrontColor = "white";
      break;
    case "Closed - Wave 2":
      stateFormStatusBgcolor = "#333333";
      stateFormStatusFrontColor = "white";
      break;
    default:
      stateFormStatusBgcolor = "#FFFFFF";
      stateFormStatusFrontColor = "#000000";
      break;
  }
  return {
    bgColor: stateFormStatusBgcolor,
    fontColor: stateFormStatusFrontColor,
  };
};

export const GetBanner = (user) => {
  if (!user) {
    return null;
  }

  const banner = user["lmg/store_banner"];
  if (!banner) {
    return null;
  }
  return banner.toLowerCase().replace(" ", "");
};
