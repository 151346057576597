import React from "react";
import { observer } from "mobx-react-lite";
import { Stack } from "@mui/material";
import Link from "@mui/material/Link";

const AttachmentFiles = observer(({ attachments, scrbMode, editMode }) => {
  if (attachments === null) {
    return null;
  }

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      {attachments.length > 0 &&
        attachments.map((attachment) => (
          <Link
            key={attachment.fileName}
            href={attachment.downloadUrl}
            target="_blank"
            rel="noreferrer"
          >
            {attachment.fileName}
          </Link>
        ))}
    </Stack>
  );
});

export default AttachmentFiles;
