import React, { useEffect, useContext } from "react";
import { GlobalStore } from "../../stores/globalContext";
import { observer } from "mobx-react-lite";
import ScreenTitle from "../shared/screenTitle";
import ToolBar from "./toolBar";
import MainTable from "./mainTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const AllForms = observer((props) => {
  const stores = useContext(GlobalStore);
  const [promotions, setPromotions] = React.useState([]);
  const { promo, year, banner } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    if (user.email) {
      const getData = async () => {
        const accessToken = await getAccessTokenSilently();
        let result = await stores.member.loginWithKey(user, accessToken);
        if (result.ok) {
          let data = await stores.member.getPromotions();
          if (data) setPromotions(data);
        } else {
          setErrorMessage(
            "No user or data found!! - (Please check the user exists and has permissions to store data.)",
          );
        }
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedYear = (event) => {
    //setAge(event.target.value);
    if (promo) {
      navigate(`/${banner}/all-forms/${promo}/${event.target.value}`);
    } else {
      navigate(`/${banner}/all-forms/all/${event.target.value}`);
    }
  };

  const handlePromoSearch = (event) => {
    if (year && event.target.value) {
      navigate(`/${banner}/all-forms/${event.target.value}/${year}`);
    } else if (year && !event.target.value) {
      navigate(`/${banner}/all-forms/all/${year}`);
    } else if (event.target.value) {
      navigate(`/${banner}/all-forms/${event.target.value}`);
    } else {
      navigate(`/${banner}/all-forms`);
    }
  };

  let promotionData = promotions;
  // search by name
  if (promo && promo !== "all") {
    let search_text = promo;
    promotionData = promotionData.filter(function (item) {
      return item["promotionCode"]
        .toUpperCase()
        .includes(search_text.toUpperCase());
    });
  }

  let yearsSet = new Set();
  let yearsArray = [];
  promotionData.forEach(function (item) {
    let promotionCode = item["promotionCode"];
    let year = promotionCode.split("-").pop();
    if (/^\d{4}$/.test(year)) {
      yearsSet.add(year);
    }
  });
  yearsArray.push(2023);
  yearsArray = Array.from(yearsSet);

  // search by year
  let selectedYear;
  if (year) {
    selectedYear = year;
  } else {
    selectedYear = new Date().getFullYear().toString();
  }

  let filteredByYear = selectedYear.split(",");
  promotionData = promotionData.filter(function (item) {
    return filteredByYear.includes(item["promotionCode"].split("-").pop());
  });

  if (errorMessage !== "") {
    return (
      <div>
        <ScreenTitle title={"Scan Rebate Form"} />
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <strong>{errorMessage}</strong>
        </Alert>
      </div>
    );
  }

  if (!yearsArray || yearsArray.length < 1) {
    return (
      <div>
        <ScreenTitle title={"Scan Rebate Form"} />
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <br />
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Promotions</Typography>
      </Breadcrumbs>
      <ScreenTitle title={"Scan Rebate Form"} />
      <ToolBar
        years={yearsArray}
        selectedYear={selectedYear}
        handleSelectedYear={handleSelectedYear}
        handlePromoSearch={handlePromoSearch}
        promoSearch={promo}
      />
      <MainTable data={promotionData} />
    </div>
  );
});

export default AllForms;
