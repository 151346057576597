import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const CallbackPage = () => {
  const { isAuthenticated } = useAuth0();
  console.log(isAuthenticated);

  if (isAuthenticated) {
  }

  return (
    <div className="page-layout">
      <div className="page-layout__content" />
    </div>
  );
};

export default CallbackPage;
