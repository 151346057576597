import { jsPDF } from "jspdf";
import { reformatDate } from "../main/mainTable";
import "jspdf-autotable";
import moment from "moment";
import BottlemartLogo from "../../images/BottlemartLogo.png";
import HarryBrown from "../../images/HarryBrown.png";
import SipNSaveLogo from "../../images/SipNSaveLogo.png";
import TC_WA_LOGO from "../../images/TC_WA_LOGO.png";

// Create Document Component
const MyDocument = (props) => {
  let bodyArray = [];
  let memberType = "";
  let totalAmount = 0;

  const rawGetRebateData = props.data;
  const venueDetailData = rawGetRebateData["rebateList"];

  if (rawGetRebateData.memberType === "Manual") {
    venueDetailData.forEach((row, index) => {
      const currentProduct = row;
      const rangeCode = row.rangeCode;
      const volume = row.volume;
      const productName = currentProduct.productName;
      const multibuyQuantity = currentProduct.multibuyQuantity;
      const promotionDate =
        reformatDate(currentProduct.productPromoStartDate) +
        " - " +
        reformatDate(currentProduct.productPromoEndDate);
      const rebatePerProduct = currentProduct.rebateAmount.toFixed(2);
      const venueQuantity =
        currentProduct.venueQuantity !== null
          ? currentProduct.venueQuantity
          : "-";
      const stateQuantity =
        currentProduct.stateQuantity !== null
          ? currentProduct.stateQuantity
          : "-";

      const totalPayable = (
        currentProduct.rebateAmount *
        (currentProduct.stateQuantity === null
          ? Number(currentProduct.venueQuantity)
          : currentProduct.stateQuantity)
      ).toFixed(2);

      totalAmount += parseFloat(totalPayable);

      const element = [
        rangeCode,
        productName,
        volume,
        multibuyQuantity,
        promotionDate,
        rebatePerProduct,
        venueQuantity,
        stateQuantity,
        totalPayable,
      ];
      bodyArray.push(element);
    });
  } else {
    venueDetailData.forEach((row, index) => {
      const currentProduct = row;

      const rangeCode = row.rangeCode;

      const volume = currentProduct.volume;
      const productName = currentProduct.productName;
      const rangeName = currentProduct.rangeName;
      const multibuyQuantity = currentProduct.multibuyQuantity;
      const promotionDate =
        reformatDate(currentProduct.productPromoStartDate) +
        " - " +
        reformatDate(currentProduct.productPromoEndDate);
      const rebatePerProduct = currentProduct.rebateAmount.toFixed(2);
      const scanQuantity =
        currentProduct.scanQuantity !== null
          ? currentProduct.scanQuantity
          : "-";
      const venueQuantity =
        currentProduct.venueQuantity !== null
          ? currentProduct.venueQuantity
          : "-";
      const stateQuantity =
        currentProduct.stateQuantity !== null
          ? currentProduct.stateQuantity
          : "-";
      const extendedProductCode = currentProduct.extendedProductCode;

      const totalPayable = (
        currentProduct.rebateAmount *
        (currentProduct.stateQuantity === null
          ? currentProduct.venueQuantity === null
            ? Number(currentProduct.scanQuantity)
            : currentProduct.venueQuantity
          : currentProduct.stateQuantity)
      ).toFixed(2);

      totalAmount += parseFloat(totalPayable);

      const element = [
        rangeCode,
        productName,
        extendedProductCode,
        rangeName,
        volume,
        multibuyQuantity,
        promotionDate,
        rebatePerProduct,
        scanQuantity,
        venueQuantity,
        stateQuantity,
        totalPayable,
      ];
      bodyArray.push(element);
    });
  }

  let total_amount = Number(totalAmount).toFixed(2);
  let doc = new jsPDF();

  let columnStylesManual = {
    0: { cellWidth: 15 },
    1: { cellWidth: 45 },
    2: { cellWidth: 15 },
    3: { cellWidth: 15 },
    4: { cellWidth: 30 },
    5: { cellWidth: 20 },
    6: { cellWidth: 15 },
    7: { cellWidth: 15 },
    8: { cellWidth: 15 },
  };

  let columnStylesNostraData = {
    0: { cellWidth: 15 },
    1: { cellWidth: 20 },
    2: { cellWidth: 15 },
    3: { cellWidth: 20 },
    4: { cellWidth: 15 },
    5: { cellWidth: 10 },
    6: { cellWidth: 20 },
    7: { cellWidth: 15 },
    8: { cellWidth: 10 },
    9: { cellWidth: 15 },
    10: { cellWidth: 15 },
    11: { cellWidth: 15 },
  };

  switch (rawGetRebateData.bannerName) {
    case "Harry Brown":
      doc.setFillColor(255, 255, 255);
      doc.rect(15, 10, 100, 25, "F");
      doc.addImage(HarryBrown, "PNG", 15, 10, 98, 25);
      break;
    case "Bottlemart":
    case "Bottlemart Express":
      doc.setFillColor(0, 84, 166);
      doc.rect(15, 10, 100, 25, "F");
      doc.addImage(BottlemartLogo, "PNG", 15, 10, 98, 25);
      break;
    case "Sip n Save":
    case "Sip n Save Cellars":
      doc.setFillColor(255, 221, 0);
      doc.rect(15, 10, 100, 25, "F");
      doc.addImage(SipNSaveLogo, "PNG", 15, 10, 98, 25);
      break;
    case "Thirsty Camel":
      doc.setFillColor(160, 206, 102);
      doc.rect(15, 10, 100, 25, "F");
      doc.addImage(TC_WA_LOGO, "PNG", 15, 10, 98, 25);
      break;
    default:
    // code block
  }

  let finalY = 25;
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text(
    `${rawGetRebateData["promoCode"]} ${rawGetRebateData["bannerName"]} ${rawGetRebateData["stateName"]} : Zone ${rawGetRebateData["zoneNumber"]} Scan Rebate Form `,
    15,
    finalY + 20,
  );
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("Promotional Period: ", 15, finalY + 30);
  doc.setFont("helvetica", "normal");
  doc.text(
    `${reformatDate(rawGetRebateData["promotionStartDate"])} to ${reformatDate(
      rawGetRebateData["promotionEndDate"],
    )}`,
    50,
    finalY + 30,
  );
  doc.setFont("helvetica", "bold");
  doc.text("Venue name: ", 15, finalY + 37);
  doc.setFont("helvetica", "normal");
  doc.text(
    `${rawGetRebateData["venueName"]}, ${rawGetRebateData["suburb"]} `,
    38,
    finalY + 37,
  );
  doc.setFont("helvetica", "bold");
  doc.text("Last Submission Date: ", 15, finalY + 53);
  doc.setFont("helvetica", "normal");
  doc.text(
    `${reformatDate(
      rawGetRebateData["venueLockDate"],
    )} Forms submitted late will not be honoured.`,
    54,
    finalY + 53,
  );

  if (memberType === "Manual") {
    doc.autoTable({
      headStyles: { fillColor: [0, 84, 166] },
      styles: { fontSize: 7, valign: "middle" },
      startY: finalY + 57,
      columnStyles: columnStylesManual,
      head: [
        [
          "Range Code",
          "Promoted Product",
          "Volume",
          "Multi-Buy QTY",
          "Promo Period",
          "Rebate per Promoted Product",
          "Member adjusted QTY",
          "Approved QTY",
          "Total Payable",
        ],
      ],
      body: bodyArray,
    });
  } else {
    doc.autoTable({
      headStyles: { fillColor: [0, 84, 166] },
      styles: { fontSize: 7, valign: "middle" },
      startY: finalY + 57,
      columnStyles: columnStylesNostraData,
      head: [
        [
          "Range Code",
          "Range Name",
          "Extended Product Code",
          "Extended Product Description",
          "Volume",
          "Multi-Buy QTY",
          "Promo Period",
          "Rebate per Promoted Product",
          "Scan QTY",
          "Member adjusted QTY",
          "Approved QTY",
          "Total Payable",
        ],
      ],
      body: bodyArray,
    });
  }

  finalY = doc.lastAutoTable.finalY;
  doc.line(14, finalY, 202, finalY);
  let breakLineTotal = doc.splitTextToSize("Total rebate excl GST:", 20);
  doc.setFontSize(8);
  doc.line(167, finalY, 167, finalY + 12);
  doc.line(167, finalY + 12, 186, finalY + 12);
  doc.line(186, finalY + 12, 186, finalY);
  doc.text(breakLineTotal, 169, finalY + 5);
  doc.line(186, finalY + 12, 202, finalY + 12);
  doc.line(202, finalY + 12, 202, finalY);
  doc.text("$" + total_amount, 188, finalY + 5);

  if (finalY + 15 > 270) {
    doc.addPage();
    finalY = 0;
  }

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("Completed by: ", 15, finalY + 20);
  doc.setFont("helvetica", "normal");
  doc.text(rawGetRebateData["completedBy"] ?? "Not Completed", 42, finalY + 20);
  doc.setFont("helvetica", "bold");
  doc.text("Completed date: ", 15, finalY + 25);
  doc.setFont("helvetica", "normal");
  doc.text(
    reformatDate(rawGetRebateData["dateSubmitted"] ?? "Not Submitted"),
    44,
    finalY + 25,
  );
  doc.setFont("helvetica", "bold");
  doc.text("Comment: ", 15, finalY + 30);
  doc.setFont("helvetica", "normal");
  doc.text(rawGetRebateData["comment"] ?? "", 44, finalY + 30);

  doc.setFont("helvetica", "bold");
  doc.text("Terms & Conditions", 15, 285);
  doc.setFont("helvetica", "normal");
  doc.text(
    "This form is subject to the terms & conditions as displayed on the Online Rebate Form.",
    15,
    290,
  );

  doc.save(
    `${props.storeId}-${rawGetRebateData["promoCode"]}-${moment().format(
      "DDMMYY",
    )}.pdf`,
  );

  //let url = window.location.toString();
  //window.location = url.replace('&download=true', '');
};

export default MyDocument;
