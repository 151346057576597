import React from "react";
import { Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import AppBar from "../shared/appBar";
import { Outlet } from "react-router-dom";
import Footer from "../shared/footer";

const MainScreen = observer((props) => {
  return (
    <>
      <AppBar />
      <Container>
        <Outlet />
      </Container>
      <br />
      <Footer />
    </>
  );
});

export default MainScreen;
