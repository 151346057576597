import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GlobalStore } from "./stores/globalContext";
import stores from "./stores";
import MainScreen from "./components/main/main";
import { ThemeProvider } from "@mui/material";
import AllForms from "./components/main/allForms";
import VenueList from "./components/venues/venueList";
import OnlineRebateForm from "./components/rebate-form/onlineRebateForm";
import { mainTheme } from "./components/shared/theme";
import { AuthenticationGuard } from "./components/authentication-guard";
import CallbackPage from "./components/callback";
import { GetBanner } from "./stores/functions";
import { useAuth0 } from "@auth0/auth0-react";
import { Provider, ErrorBoundary } from "@rollbar/react";

const ThemeContext = React.createContext(mainTheme());
export const useMyTheme = () => React.useContext(ThemeContext);

function App() {
  const { user } = useAuth0();
  const banner = GetBanner(user);
  const [theme, setMyTheme] = useState(mainTheme(banner));

  useEffect(() => {
    setMyTheme(mainTheme(banner));
    stores.member.setBanner(banner);
  }, [banner]);

  const rollbarConfig = {
    enabled: process.env.REACT_APP_ROLLBAR_ENABLED === "1",
    accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM,
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    captureUncaught: true,
    captureUnhandledRejections: true,
    server: {
      person: {
        id: user?.id,
        email: user?.email,
        username: user?.name,
      },
    },
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ThemeContext.Provider value={{ theme, setMyTheme }}>
          <ThemeProvider theme={theme}>
            <GlobalStore.Provider value={stores}>
              <Routes>
                <Route
                  path="*"
                  element={<Navigate to={`/${banner}/all-forms`} />}
                />
                {/*
                <Route path="/:banner/login" element={<AuthenticationGuard component={AllForms} />}/>
                <Route index element={<Navigate to="/bottlemart/login" />} />
              */}
                <Route
                  path="/"
                  element={<AuthenticationGuard component={MainScreen} />}
                >
                  <Route
                    index
                    element={<Navigate to={`/${banner}/all-forms`} />}
                  />
                  <Route
                    path=":banner/all-forms/:promo?/:year?"
                    element={<AllForms />}
                  />
                  <Route
                    path=":banner/venue-list/:promoId"
                    element={<VenueList />}
                  />
                  <Route
                    path=":banner/online-rebate-form/:promoId/:storeId"
                    element={<OnlineRebateForm />}
                  />
                </Route>
                <Route path="/callback" element={<CallbackPage />} />
              </Routes>
            </GlobalStore.Provider>
          </ThemeProvider>
        </ThemeContext.Provider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
