import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: theme.palette.grey["300"],
    borderWidth: 1,
    borderStyle: "solid",
    lineHeight: 1.2,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 1,
    lineHeight: 1,
    fontWeight: 700,
  },
}));

export const StyledTableCellBody = styled(TableCell)(({ theme, active }) => ({
  border: "1px solid " + theme.palette.grey["300"],
  [theme.breakpoints.up("sm")]: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: 500,
    padding: 12,
    color: active ? "#FFFFFF" : "#000000",
    fontFamily: "Lato",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    fontWeight: 500,
    padding: 2,
    color: active ? "#FFFFFF" : "#000000",
    fontFamily: "Lato",
  },
}));
