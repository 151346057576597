import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import { StyledTableCell, StyledTableCellBody } from "../shared/tableStyle";
import { useContext } from "react";
import { GlobalStore } from "../../stores/globalContext";
import { styled } from "@mui/material/styles";

export const StyledTableRow = styled(TableRow)(({
  theme,
  backgroundColor,
  isactive,
}) => {
  if (backgroundColor) {
    return {
      backgroundColor: backgroundColor,
    };
  }
  if (isactive) {
    return {
      backgroundColor: theme.palette.success.light,
    };
  }
});

export default function MainTable(props) {
  const [dateFormat] = React.useState("DD/MM/YYYY");
  const stores = useContext(GlobalStore);
  const navigate = useNavigate();
  const { banner } = useParams();

  const handleOnClick = (event, promoCode) => {
    event.preventDefault();
    navigate(`/${banner}/venue-list/${promoCode}`);
  };

  if (!props.data || props.data.length < 1) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sm={{ minWidth: 650 }} size="small" aria-label="Promotions Table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Promotion No.</StyledTableCell>
            {stores.member.getScrbMode() === "state" ? (
              <>
                <StyledTableCell align="left">
                  Manual State
                  <br />
                  Lock Date
                </StyledTableCell>
                <StyledTableCell align="left">
                  NostraData State
                  <br />
                  Lock Date
                </StyledTableCell>
              </>
            ) : null}
            <StyledTableCell align="left">
              Manual Venue
              <br />
              Lock Date
            </StyledTableCell>
            <StyledTableCell align="left">
              NostraData Venue
              <br />
              Lock Date
            </StyledTableCell>
            <StyledTableCell align="left">Promotion Period</StyledTableCell>
            <StyledTableCell align="left">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => {
            let isActive = false;
            let lockDateArray = [
              row.manualVenueLockDate.substr(0, 10),
              row.nostraDataVenueLockDate.substr(0, 10),
            ];

            if (stores.member.getScrbMode() === "state") {
              lockDateArray = [
                row.manualStateLockDate.substr(0, 10),
                row.nostraDataStateLockDate.substr(0, 10),
              ];
            }

            for (let i = 0; i < lockDateArray.length; i++) {
              if (compareDateOfRow(row.promotionEndDate, lockDateArray[i])) {
                isActive = true;
              }
            }

            return (
              <StyledTableRow
                isactive={isActive ? 1 : 0}
                key={row.promotionCode + row.promotionStartDate + index}
              >
                <StyledTableCellBody
                  component="th"
                  scope="row"
                  active={isActive ? 1 : 0}
                >
                  {row.promotionCode}
                </StyledTableCellBody>
                {stores.member.getScrbMode() === "state" ? (
                  compareLeftDays(row["manualStateLockDate"]) ? (
                    <StyledTableCellBody
                      align="center"
                      active={isActive ? 1 : 0}
                    >
                      <span className="scrb-forms-warning">
                        {reformatDate(row["manualStateLockDate"])}
                        <br />
                        {showLeftDays(row["manualStateLockDate"])}
                      </span>
                    </StyledTableCellBody>
                  ) : (
                    <StyledTableCellBody
                      active={isActive ? 1 : 0}
                      align="center"
                    >
                      <Moment
                        date={row.manualStateLockDate}
                        format={dateFormat}
                      />
                    </StyledTableCellBody>
                  )
                ) : null}
                {stores.member.getScrbMode() === "state" ? (
                  compareLeftDays(row["nostraDataStateLockDate"]) ? (
                    <StyledTableCellBody
                      align="center"
                      active={isActive ? 1 : 0}
                    >
                      <span className="scrb-forms-warning">
                        {reformatDate(row["nostraDataStateLockDate"])}
                        <br />
                        {showLeftDays(row["nostraDataStateLockDate"])}
                      </span>
                    </StyledTableCellBody>
                  ) : (
                    <StyledTableCellBody
                      active={isActive ? 1 : 0}
                      align="center"
                    >
                      <Moment
                        date={row.nostraDataStateLockDate}
                        format={dateFormat}
                      />
                    </StyledTableCellBody>
                  )
                ) : null}
                {compareLeftDays(row["manualVenueLockDate"]) ? (
                  <StyledTableCellBody active={isActive ? 1 : 0} align="center">
                    <span className="scrb-forms-warning">
                      {reformatDate(row["manualVenueLockDate"])}
                      <br />
                      {showLeftDays(row["manualVenueLockDate"])}
                    </span>
                  </StyledTableCellBody>
                ) : (
                  <StyledTableCellBody active={isActive ? 1 : 0} align="center">
                    <Moment
                      date={row.manualVenueLockDate}
                      format={dateFormat}
                    />
                  </StyledTableCellBody>
                )}
                {compareLeftDays(row["manualVenueLockDate"]) ? (
                  <StyledTableCellBody active={isActive ? 1 : 0} align="center">
                    <span className="scrb-forms-warning">
                      {reformatDate(row["nostraDataVenueLockDate"])}
                      <br />
                      {showLeftDays(row["nostraDataVenueLockDate"])}
                    </span>
                  </StyledTableCellBody>
                ) : (
                  <StyledTableCellBody active={isActive ? 1 : 0} align="center">
                    <Moment
                      date={row.nostraDataVenueLockDate}
                      format={dateFormat}
                    />
                  </StyledTableCellBody>
                )}
                <StyledTableCellBody active={isActive ? 1 : 0} align="center">
                  <Moment date={row.promotionStartDate} format={dateFormat} /> -{" "}
                  <Moment date={row.promotionEndDate} format={dateFormat} />
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  <button
                    className="select-button"
                    onClick={(e) => handleOnClick(e, row.promotionCode)}
                  >
                    View
                  </button>
                </StyledTableCellBody>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function compareDateOfRow(endDate, lockDate) {
  endDate = endDate.substr(0, 10);
  lockDate = lockDate.substr(0, 10);
  let today = new Date().toISOString().slice(0, 10);

  if (today <= lockDate && today > endDate) {
    return true;
  } else {
    return false;
  }
}

function compareLeftDays(targetDate) {
  targetDate = new Date(targetDate.substr(0, 10));
  let today = new Date();
  let daysLeft = Math.floor((targetDate - today) / (1000 * 60 * 60 * 24));

  if (daysLeft <= 5 && daysLeft >= 0) {
    return true;
  } else {
    return false;
  }
}

function showLeftDays(targetDate) {
  targetDate = new Date(targetDate.substr(0, 10));
  let today = new Date();
  let daysLeft = Math.floor((targetDate - today) / (1000 * 60 * 60 * 24));

  if (daysLeft <= 5 && daysLeft > 0) {
    return "(" + daysLeft + " days left)";
  } else if (daysLeft === 0) {
    return "(Last day)";
  }
}

export function reformatDate(curDate) {
  let dateArr = curDate.substr(0, 10).split("-");
  return dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];
}
