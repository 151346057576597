import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { GlobalStore } from "../../stores/globalContext";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";

const MarkAsReviewedComment = observer(() => {
  const [open, setOpen] = React.useState(false);
  const stores = useContext(GlobalStore);
  let [, setSearchParams] = useSearchParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = async () => {
    setSearchParams((searchParams) => {
      searchParams.set("edit", "false");
      return searchParams;
    });
    handleClose();
    await stores.member.handleFormSubmit();
  };

  return (
    <div>
      <Button
        variant="contained"
        size="large"
        style={{ paddingLeft: 50, paddingRight: 50 }}
        onClick={handleClickOpen}
      >
        Mark As Reviewed
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Completed By:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If changes were made, please explain your reasons for changing the
            product figures.
          </DialogContentText>
          <div style={{ marginTop: 20 }}>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={6}
              fullWidth
              value={
                stores.member.scanRebateData.comment
                  ? stores.member.scanRebateData.comment
                  : ""
              }
              onChange={stores.member.comment}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={
              stores.member.scanRebateData.comment !== null &&
              stores.member.scanRebateData.comment.length < 5
            }
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
export default MarkAsReviewedComment;
