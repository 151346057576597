import * as React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Logo } from "../shared/theme";
import { useParams } from "react-router-dom";
import { Link, Typography } from "@mui/material";

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.lmg.com.au/">
        Liquor Marketing Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Footer = () => {
  const { banner } = useParams();
  return (
    <Container
      sx={{
        marginTop: "calc(10% + 60px)",
        width: "100%",
        height: "175px",
        bottom: 0,
      }}
      component="footer"
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "primary.main",
            my: 1,
          }}
        >
          <div>
            <Logo banner={banner} />
          </div>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mb: 2,
          }}
        >
          <Copyright />
        </Box>
      </Container>
    </Container>
  );
};
export default Footer;
