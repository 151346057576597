const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const postToApi = async (data, url, accessToken) => {
  try {
    let response = await fetch(`${apiServerUrl}${url}`, {
      method: "POST",
      credentials: "include",
      crossDomain: true,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });
    //let responseJson = await response.json();
    //console.log(response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getFromApi = async (data, url) => {
  //let myHeaders = new Headers();
  //myHeaders.append("Access-Control-Allow-Origin", "*");

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,PATCH,OPTIONS",
  };

  let requestOptions = {
    method: "GET",
    crossDomain: true,
    credentials: "include",
    headers: headers,
    redirect: "follow",
  };

  try {
    let response = await fetch(`${apiServerUrl}${url}`, requestOptions);

    if (response.status === 200) {
      let responseJson = await response.json();
      return responseJson;
    }

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const putToApi = async (data, url) => {
  const key = sessionStorage.getItem("@USER_API_KEY");
  try {
    let response = await fetch(`${apiServerUrl}${url}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${key}`,
      },
      body: data,
    });
    let responseJson = await response.json();
    console.log(responseJson);

    if (responseJson && responseJson.hasOwnProperty("error")) {
      if (responseJson.error.hasOwnProperty("TokenExpiredError")) {
        if (responseJson.error.TokenExpiredError === true) {
          console.log("TokenExpiredError Go to Login");
          sessionStorage.clear();
          window.location.reload();
        }
      }
    }

    return responseJson;
  } catch (error) {
    console.error(error);
  }
};

export const deleteToApi = async (data, url) => {
  const key = sessionStorage.getItem("@USER_API_KEY");
  try {
    let response = await fetch(`${apiServerUrl}${url}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${key}`,
      },
      body: data,
    });
    let responseJson = await response.json();

    if (responseJson && responseJson.hasOwnProperty("error")) {
      if (responseJson.error.hasOwnProperty("TokenExpiredError")) {
        if (responseJson.error.TokenExpiredError === true) {
          console.log("TokenExpiredError Go to Login");
          sessionStorage.clear();
          window.location.reload();
        }
      }
    }

    return responseJson;
  } catch (error) {
    console.error(error);
  }
};
