import * as React from "react";
import { StyledTableCellBody } from "../shared/tableStyle";
import { Stack } from "@mui/material";
import Moment from "react-moment";
import { StyledTableRow, DateFormat } from "./rebateListTable";
import { useContext, useEffect } from "react";
import { GlobalStore } from "../../stores/globalContext";
import { observer } from "mobx-react-lite";

const RowManual = observer((props) => {
  const stores = useContext(GlobalStore);
  const currentProduct = props.row;

  useEffect(() => {
    stores.member.updateRebateAmount(currentProduct);
    props.handleTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct["venueQuantity"], currentProduct["stateQuantity"]]);

  return (
    <React.Fragment key={props.row["rangeCode"]}>
      <StyledTableRow hover={true}>
        <StyledTableCellBody component="th" scope="row">
          <Stack
            direction="row"
            spacing={0}
            mb={0}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {props.row["rangeCode"]}
          </Stack>
        </StyledTableCellBody>
        <StyledTableCellBody align="left" style={{ minWidth: 225 }}>
          {currentProduct["productName"]}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          {props.row.volume}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          {currentProduct["multibuyQuantity"]}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          <Moment
            date={currentProduct["productPromoStartDate"]}
            format={DateFormat}
          />
          <br />
          <Moment
            date={currentProduct["productPromoEndDate"]}
            format={DateFormat}
          />
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          ${Number(currentProduct["rebateAmount"]).toFixed(2)}
        </StyledTableCellBody>

        {stores.member.getScrbMode() === "state" ? (
          <>
            <StyledTableCellBody align="center">
              {currentProduct["venueQuantity"]}
            </StyledTableCellBody>
            {
              // change the state quantity if it's under editing
              props.edit === true &&
              (props.data["stateFormStatus"] === "Available" ||
                props.data["stateFormStatus"] === "Reviewed") ? (
                <StyledTableCellBody align="center">
                  <input
                    type="number"
                    min="0"
                    style={{ width: 50 }}
                    data-rebateid={currentProduct["rebateId"]}
                    onChange={(e) =>
                      stores.member.updateVenueQuantityByRebateId(
                        currentProduct["rebateId"],
                        e.target.value,
                        "stateQuantity",
                      )
                    }
                    value={
                      currentProduct["stateQuantity"]
                        ? currentProduct["stateQuantity"]
                        : ""
                    }
                  />
                </StyledTableCellBody>
              ) : (
                <StyledTableCellBody align="center">
                  {Number(currentProduct["stateQuantity"])}
                </StyledTableCellBody>
              )
            }
          </>
        ) : (
          <>
            {
              // change the state quantity if it's under editing}
              props.edit === true &&
              (props.data["venueFormStatus"] === "Available" ||
                props.data["venueFormStatus"] === "Submitted") ? (
                <StyledTableCellBody align="center">
                  <input
                    type="number"
                    min="0"
                    style={{ width: 50 }}
                    onChange={(e) =>
                      stores.member.updateVenueQuantityByRebateId(
                        currentProduct["rebateId"],
                        e.target.value,
                        "venueQuantity",
                      )
                    }
                    value={
                      currentProduct["venueQuantity"]
                        ? currentProduct["venueQuantity"]
                        : ""
                    }
                  />
                </StyledTableCellBody>
              ) : (
                <StyledTableCellBody align="center">
                  {Number(currentProduct["venueQuantity"])}
                </StyledTableCellBody>
              )
            }
          </>
        )}
        <StyledTableCellBody align="center">
          $
          {Number(currentProduct.payable ? currentProduct.payable : 0).toFixed(
            2,
          )}
        </StyledTableCellBody>
      </StyledTableRow>
    </React.Fragment>
  );
});

export default RowManual;
