import { makeObservable, observable, runInAction } from "mobx";
import { getFromApi, postToApi } from "./fetchStore";

export default class MemberStore {
  constructor() {
    makeObservable(this, {
      rebateData: observable,
      banner: observable,
      scrb_mode: observable,
      saved: observable,
      savedFailed: observable,
    });
    this._retrieveData();
  }
  banner = "bottlemart";
  scrb_mode = "";
  rebateData = [];
  saved = false;
  savedFailed = false;

  get savedSuccess() {
    return this.saved;
  }

  setSavedSuccess(bool) {
    runInAction(() => {
      this.saved = bool;
    });
  }

  get savedFail() {
    return this.savedFailed;
  }

  setSavedFail(bool) {
    runInAction(() => {
      this.savedFailed = bool;
    });
  }

  setBanner = (banner) => {
    switch (banner) {
      case "thirstycamel":
        // code block
        runInAction(() => {
          this.banner = "thirstycamel";
        });
        break;
      case "bottlemart":
        // code block=
        runInAction(() => {
          this.banner = "bottlemart";
        });
        break;
      case "sipnsave":
        // code block
        runInAction(() => {
          this.banner = "sipnsave";
        });
        break;
      case "harrybrown":
        // code block
        runInAction(() => {
          this.banner = "bottlemart";
        });
        break;
      default:
        // code block
        runInAction(() => {
          this.banner = "bottlemart";
        });
    }
  };

  _retrieveData = () => {
    try {
      const data = sessionStorage.getItem("@USER_DATA");
      if (data !== null) {
        const user = JSON.parse(data);
        runInAction(() => {
          //this.emailAddress = user.emailAddress;
          this.scrb_mode = user.scrb_mode;
        });
      }
    } catch (error) {
      // Error retrieving data
      console.error("Error retrieving data: sessionStorage");
    }
  };

  get scanRebateData() {
    return this.rebateData;
  }

  handleFormSubmit = async () => {
    let saveForm = {};
    if (this.getScrbMode() === "state") {
      saveForm = {
        promoCode: this.rebateData.promoCode,
        VenueCode: this.rebateData.venueCode,
        Comment: this.scanRebateData.comment,
        Rebates: this.getFormData(),
        Attachments: this.rebateData.attachments,
      };
    } else {
      saveForm = {
        promoCode: this.rebateData.promoCode,
        VenueCode: this.rebateData.venueCode,
        completedBy: this.rebateData.completedBy,
        Rebates: this.getFormData(),
        Attachments: this.rebateData.attachments,
      };
    }
    //console.log(saveForm);
    const result = await this.postRebates(saveForm);
    if (result.ok) {
      this.setSavedFail(false);
      this.setSavedSuccess(true);
    } else {
      this.setSavedSuccess(false);
      this.setSavedFail(true);
    }
  };

  // Function to update the venueQuantity based on rebateId
  updateVenueQuantityByRebateId = (rebateId, qty, scrbMode) => {
    // Iterate over the extendedRebateList array
    runInAction(() => {
      // Find the rebate object with matching rebateId
      const targetRebate = this.rebateData.rebateList.find(
        (item) => item.rebateId === rebateId,
      );
      if (targetRebate) {
        // Update the venueQuantity property
        if (this.getScrbMode() === "state") {
          runInAction(() => {
            targetRebate.stateQuantity = qty;
          });
        } else {
          runInAction(() => {
            targetRebate.venueQuantity = qty;
          });
        }
      }
    });
  };

  updateRebateNostraAmount = (currentProduct) => {
    runInAction(() => {
      const targetRebate = this.rebateData.rebateList.find(
        (item) => item.rebateId === currentProduct["rebateId"],
      );

      if (targetRebate) {
        let rebateAmount =
          currentProduct["rebateAmount"] *
          (currentProduct["stateQuantity"] === null
            ? currentProduct["venueQuantity"] === null
              ? Number(currentProduct["scanQuantity"])
              : currentProduct["venueQuantity"]
            : currentProduct["stateQuantity"]);
        runInAction(() => {
          targetRebate.payable = rebateAmount;
        });
      }
    });
  };

  updateRebateAmount = (currentProduct) => {
    runInAction(() => {
      // Find the rebate object with matching rebateId
      const targetRebate = this.rebateData.rebateList.find(
        (item) => item.rebateId === currentProduct["rebateId"],
      );
      if (targetRebate) {
        let rebateAmount;
        if (this.getScrbMode() === "state") {
          rebateAmount =
            currentProduct["rebateAmount"] *
            (currentProduct["stateQuantity"] === null
              ? Number(currentProduct["venueQuantity"])
              : Number(currentProduct["stateQuantity"]));
        } else {
          rebateAmount =
            currentProduct["rebateAmount"] *
            Number(currentProduct["venueQuantity"]);
        }
        runInAction(() => {
          targetRebate.payable = rebateAmount;
        });
      }
    });
  };

  updateAttachments = async (upload) => {
    let attachmentsFormData = [];
    let newFile = {
      fileName: upload.Key,
      downloadUrl: upload.Location,
    };

    if (this.rebateData.attachments === null) {
      attachmentsFormData.push(newFile);
      runInAction(() => {
        this.rebateData.attachments = attachmentsFormData;
      });
    } else if (Array.isArray(this.rebateData.attachments)) {
      runInAction(() => {
        this.rebateData.attachments.push(newFile);
      });
    }
  };

  getRebateSubTotal = (rangeCode) => {
    let subTotal = 0;
    // const targetRange = this.rebateData.rebateList.find(
    //   (item) => item.rangeCode === rangeCode,
    // );
    // console.log(targetRange);
    // targetRange.rebateList.forEach((item) => {
    //   if (item.hasOwnProperty("payable")) {
    //     subTotal += item.payable;
    //   }
    // });
    return subTotal;
  };

  getRebateTotal = () => {
    let totalAmount = 0;
    this.rebateData.rebateList.forEach((item) => {
      if (item.hasOwnProperty("payable")) {
        totalAmount += item.payable;
      }
    });
    return totalAmount;
  };

  getFormData = () => {
    let editedFormData = [];
    this.rebateData.rebateList.forEach((item) => {
      if (item.hasOwnProperty("rebateId")) {
        if (this.getScrbMode() === "state") {
          editedFormData.push({
            RebateId: item.rebateId,
            StateQuantity: item.stateQuantity,
          });
        } else {
          editedFormData.push({
            RebateId: item.rebateId,
            VenueQuantity: item.venueQuantity,
          });
        }
      }
    });

    return editedFormData;
  };

  updateRebateData = (data) => {
    runInAction(() => {
      this.rebateData = data;
    });
  };

  completedBy = (event) => {
    runInAction(() => {
      this.rebateData.completedBy = event.target.value;
    });
  };
  comment = (event) => {
    //console.log(event.target.value)
    runInAction(() => {
      this.rebateData.comment = event.target.value;
    });
  };

  getScrbMode = () => {
    return this.scrb_mode;
  };

  logout = async () => {
    sessionStorage.clear();
    runInAction(() => {
      this.scrb_mode = "";
    });
  };

  loginWithKey = async (user, accessToken) => {
    const endpoint = `/Authentication/LoginWithJwt`;
    const data = {
      Email: user.email,
    };
    const result = await postToApi(JSON.stringify(data), endpoint, accessToken);
    if (result.status === 204) {
      if (user["lmg/roles"].includes("LMG_StateGM")) {
        sessionStorage.setItem(
          "@USER_DATA",
          JSON.stringify({ scrb_mode: "state" }),
        );
        runInAction(() => {
          this.scrb_mode = "state";
        });
      } else {
        sessionStorage.setItem(
          "@USER_DATA",
          JSON.stringify({ scrb_mode: "store" }),
        );
        runInAction(() => {
          this.scrb_mode = "store";
        });
      }
    }
    return result;
  };

  getPromotions = async () => {
    const endpoint = `/Legacy/GetPromotions?banner=${this.banner}`;
    const data = "";
    return await getFromApi(JSON.stringify(data), endpoint);
  };

  GetVenues = async (promoCode) => {
    const endpoint = `/Legacy/GetVenues?banner=${this.banner}&promoCode=${promoCode}`;
    const data = "";
    return await getFromApi(JSON.stringify(data), endpoint);
  };

  getRebates = async (promoCode, storeId) => {
    const endpoint = `/Legacy/GetRebates?banner=${this.banner}&promoCode=${promoCode}&storeCode=${storeId}`;
    const data = "";
    return await getFromApi(JSON.stringify(data), endpoint);
  };

  postRebates = async (data) => {
    const endpoint = `/Legacy/SaveRebates`;
    return await postToApi(JSON.stringify(data), endpoint);
  };

  getPresignedUrl = async (data) => {
    const endpoint = `/Legacy/preSignedUrl`;
    const response = await postToApi(JSON.stringify(data), endpoint);
    return await response.json();
  };

  uploadFile = async (url, image, type) => {
    let binary = atob(image.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: type });
    return await fetch(url, {
      method: "PUT",
      body: blobData,
    });
  };
}
