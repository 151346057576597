import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { GlobalStore } from "../../stores/globalContext";

function FileUpload() {
  const filesFormats = [
    ".pdf",
    ".jpg",
    ".png",
    ".ppt",
    ".pptx",
    ".doc",
    ".docx",
    "image/png",
    "image/jpeg",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const stores = useContext(GlobalStore);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState();
  const [error, setError] = useState(null);
  const rebateData = stores.member.rebateData;
  const handleFileChange = (event) => {
    setFile(null);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (event.target.files[0].size > 32000000) {
        setError(`${file.name} exceeds the 32Mb limit`);
        return;
      }
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        setError(`${file.name} is not a file type we accept`);
        return;
      }
      setError(null);
      setFile(file);
      let reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadFile = async () => {
    if (!file || !image) return null;

    const key =
      rebateData.venueCode + "/" + rebateData.promoCode + "/" + file.name;

    const toUpload = {
      key: key,
      contentType: file.type,
    };

    const url = await stores.member.getPresignedUrl(toUpload);

    await stores.member.uploadFile(url.uploadUrl, image, file.type);

    const upload = {
      Key: key,
      Location: url.downloadUrl,
    };

    await stores.member.updateAttachments(upload);
    await stores.member.handleFormSubmit();
  };

  const canUpload = file != null;

  return (
    <div>
      {error && (
        <Alert severity="error" sx={{ my: 3 }}>
          {error}
        </Alert>
      )}
      <div>
        <Button
          className="scrb-button scrb-action-attachment icons-attachment"
          variant="contained"
          component="label"
        >
          Attach File
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            accept={filesFormats.join(",")}
          />
        </Button>
        <Typography>File Selected: {file ? file.name : null}</Typography>
      </div>
      <Button
        disabled={!canUpload}
        className="scrb-button scrb-action-attachment icons-attachment"
        variant="contained"
        onClick={uploadFile}
      >
        Upload
      </Button>
      <span>
        Formats supported: pdf, jpg, png, ppt, doc. Max file size 32mb.
      </span>
    </div>
  );
}

export default FileUpload;
