import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, CircularProgress } from "@mui/material";
import Moment from "react-moment";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { StyledTableCell, StyledTableCellBody } from "../shared/tableStyle";
import { useContext } from "react";
import { GlobalStore } from "../../stores/globalContext";
import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function VenueListTable(props) {
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  let { promoId, banner } = useParams();
  const stores = useContext(GlobalStore);

  const handleOnClick = (venueLmgId, edit = false) => {
    if (edit) {
      navigate({
        pathname: `/${banner}/online-rebate-form/${promoId}/${venueLmgId}`,
        search: createSearchParams({
          edit: edit,
        }).toString(),
      });
    } else {
      navigate(`/${banner}/online-rebate-form/${promoId}/${venueLmgId}`);
    }
  };

  if (props.data.length < 1) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Promotions Table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Venue Name</StyledTableCell>
            <StyledTableCell align="left">
              Member
              <br />
              Type
            </StyledTableCell>
            <StyledTableCell align="left">
              Venue
              <br />
              Zone
            </StyledTableCell>
            <StyledTableCell align="left">State</StyledTableCell>
            {stores.member.getScrbMode() === "state" ? (
              <StyledTableCell align="left">
                State Manager
                <br />
                Lock Date
              </StyledTableCell>
            ) : (
              <StyledTableCell align="left">
                Venue
                <br />
                Lock Date
              </StyledTableCell>
            )}

            <StyledTableCell align="left">
              Promotion
              <br />
              Period
            </StyledTableCell>
            <StyledTableCell align="left" className={"scrb-hint-cell"}>
              Venue Status
              <div className="scrb-hint-bubble">
                <div>
                  Venue Status meanings:
                  <br />
                  <br />
                  <strong>N/A</strong> - The rebate form is available for View
                  Only.
                  <br />
                  <br />
                  <strong>Available</strong> - The rebate form is available for
                  the venue manager to Open, Edit and Submit.
                  <br />
                  <br />
                  <strong>Submitted</strong> - The venue manager has Submitted
                  their form.
                  <br />
                  <br />
                  <strong>Submitted - Wave 1</strong> - The venue lock date has
                  passed and the Manual or NostraData venue manager Submitted
                  their data during Wave 1. They can now only View the form.
                  <br />
                  <br />
                  <strong>Submitted - Wave 2</strong> - The venue lock date has
                  passed and the Manual venue manager Submitted their data
                  during Wave 2. They can now only View the form.
                  <br />
                  <br />
                  <strong>Not Submitted</strong> - The venue manager never
                  Edited and Submitted the form.
                </div>
              </div>
            </StyledTableCell>
            {stores.member.getScrbMode() === "state" ? (
              <StyledTableCell align="left" className={"scrb-hint-cell"}>
                State Status
                <div className="scrb-hint-bubble">
                  <div>
                    State Status meanings:
                    <br />
                    <br />
                    <strong>N/A</strong> - The promotion hasn't started yet, the
                    rebate form is available for View Only.
                    <br />
                    <br />
                    <strong>Available</strong> - The rebate form is available
                    for the state manager to Open, Edit, Save and mark as
                    Reviewed.
                    <br />
                    <br />
                    <strong>Reviewed</strong> - The rebate form has been marked
                    as Reviewed by the state manager but still is Editable until
                    the state lock date.
                    <br />
                    <br />
                    <strong>Closed</strong> - The state lock date has passed and
                    the state manager can only View the form.
                  </div>
                </div>
              </StyledTableCell>
            ) : null}
            <StyledTableCell align="left">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <TableRow
              key={row.venueLmgId}
              //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCellBody
                style={{ width: 150, color: "#000", fontWeight: 600 }}
                component="th"
                scope="row"
              >
                {row.venueName}
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                {row.memberType}
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                {row.zoneNumber}
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                {row.state}
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                <Moment date={row.lockDate} format={dateFormat} />
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                <Moment date={row.promotionStartDate} format={dateFormat} />
                <br />
                <Moment date={row.promotionEndDate} format={dateFormat} />
              </StyledTableCellBody>
              <StyledTableCellBody
                align="left"
                data-scrb-forms-formstatus={row["venueStatus"]}
              >
                <span>{row["venueStatus"]}</span>
              </StyledTableCellBody>
              {stores.member.getScrbMode() === "state" ? (
                <StyledTableCellBody
                  align="center"
                  data-scrb-forms-formstatus={row["stateStatus"]}
                >
                  <span>{row["stateStatus"]}</span>
                </StyledTableCellBody>
              ) : null}
              <ScrbCellAction row={row} handleOnClick={handleOnClick} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const ScrbCellAction = (props) => {
  const stores = useContext(GlobalStore);

  if (stores.member.getScrbMode() === "state") {
    if (
      props.row.stateStatus === "Available" ||
      props.row.stateStatus === "Reviewed"
    ) {
      //Edit button State
      return <ActionButtons {...props} />;
    } else {
      //View Only Button State
      return <ViewOnlyButton {...props} />;
    }
  } else {
    if (
      props.row.venueStatus === "Available" ||
      props.row.venueStatus === "Submitted"
    ) {
      //Edit button store
      return <ActionButtons {...props} />;
    } else {
      //View Only button store
      return <ViewOnlyButton {...props} />;
    }
  }
};

const ViewOnlyButton = (props) => {
  return (
    <StyledTableCellBody align="center">
      <span className="select-button select-dropdown">
        <Button
          style={{ color: "black" }}
          disableElevation
          onClick={() => props.handleOnClick(props.row["venueLmgId"])}
          fullWidth={true}
        >
          View
        </Button>
      </span>
    </StyledTableCellBody>
  );
};

const ActionButtons = (props) => {
  //<Button variant={"outlined"} onClick={() => handleOnClick(row.venueLmgId)}>View</Button>
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <StyledTableCellBody align="center">
      <span className="select-button select-dropdown">
        <span className="select-dropdown-arrow">
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </span>
        <Button
          style={{ justifyContent: "flex-start", color: "black" }}
          disableElevation
          onClick={() => props.handleOnClick(props.row["venueLmgId"])}
          fullWidth={true}
        >
          Open
        </Button>
      </span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper style={{ borderRadius: 5, padding: 0 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  style={{
                    width: 90,
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "#C9C9C9",
                    borderRadius: 5,
                    padding: 0,
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      props.handleOnClick(props.row["venueLmgId"], true)
                    }
                  >
                    Edit
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledTableCellBody>
  );
};
