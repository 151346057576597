import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useSearchParams } from "react-router-dom";

export default function MultipleSelectCheckmarks(props) {
  let [, setSearchParams] = useSearchParams();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSearchParams((searchParams) => {
      searchParams.set(
        props.searchParam,
        typeof value === "string" ? value.split(",") : value,
      );
      return searchParams;
    });
  };

  return (
    <div>
      <FormControl fullWidth={true}>
        <InputLabel id="multiple-checkbox-label">{props.label}</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={scrb_get_dropDownList(props.optionList, props.searchStatus)}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          renderValue={(selected) => selected.join(",")}
          size="small"
        >
          {props.optionList.map((key, val) => {
            return (
              <MenuItem dense={true} key={key} value={key}>
                <Checkbox
                  checked={props.searchStatus
                    .split(",")
                    .includes(val.toString())}
                />
                <ListItemText primary={key} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

function scrb_get_dropDownList(list, selectedValue) {
  let selectedValList = [selectedValue];
  if (selectedValue !== null) {
    selectedValList = selectedValue.split(",");
  }
  let myList = [];
  for (var i = 0; i < selectedValList.length; i++)
    myList.push(list[selectedValList[i]]);

  myList = myList.filter(function (element) {
    return element !== undefined;
  });

  return myList.toString().split(",");
}
