import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/material";
import { Person } from "@mui/icons-material";
import { useContext } from "react";
import { GlobalStore } from "../../stores/globalContext";
import { useParams } from "react-router-dom";
import { Logo } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";

const MainAppBar = () => {
  const stores = useContext(GlobalStore);
  const { banner } = useParams();
  const { logout, user } = useAuth0();
  const handleLogout = async () => {
    await stores.member.logout();
    await logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}/`,
      },
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 0 }}
            >
              <Person />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ flexGrow: 1 }}
                mt={0.5}
              >
                {user.nickname}
              </Typography>
            </IconButton>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: -10,
              }}
            >
              <Logo banner={banner} />
            </div>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default MainAppBar;
