import * as React from "react";
import { StyledTableCellBody } from "../shared/tableStyle";
import { GlobalStore } from "../../stores/globalContext";
import Moment from "react-moment";
import { observer } from "mobx-react-lite";
import TableRow from "@mui/material/TableRow";
import { DateFormat } from "./rebateListTable";

const RowNostra = observer((props) => {
  const stores = React.useContext(GlobalStore);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    stores.member.updateRebateNostraAmount(props.row);
    props.handleTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row["venueQuantity"], props.row["stateQuantity"]]);

  const editMode =
    props.edit === true &&
    (props.data["stateFormStatus"] === "Available" ||
      props.data["stateFormStatus"] === "Reviewed");

  return (
    <React.Fragment key={props.row["rangeCode"]}>
      <TableRow key={props.row.rebateId}>
        <StyledTableCellBody component="th" scope="row">
          {props.row.rangeCode}
        </StyledTableCellBody>
        <StyledTableCellBody align="left">
          {props.row.productName}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          {props.row.volume}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          {props.row.multibuyQuantity}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          <Moment date={props.row.productPromoStartDate} format={DateFormat} />
          <br />
          <Moment date={props.row.productPromoEndDate} format={DateFormat} />
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          ${props.row.rebateAmount.toFixed(2)}
        </StyledTableCellBody>
        <StyledTableCellBody align="center">
          {props.row.scanQuantity}
        </StyledTableCellBody>

        {stores.member.getScrbMode() === "state" ? (
          <>
            <StyledTableCellBody align="center">
              {props.row["venueQuantity"]}
            </StyledTableCellBody>
            {
              // change the state quantity if it's under editing
              editMode ? (
                <StyledTableCellBody align="center">
                  <input
                    type="number"
                    min="0"
                    style={{ width: 50 }}
                    data-rebateid={props.row["rebateId"]}
                    onChange={(e) =>
                      stores.member.updateVenueQuantityByRebateId(
                        props.row["rebateId"],
                        e.target.value,
                        "stateQuantity",
                      )
                    }
                    value={
                      props.row["stateQuantity"]
                        ? props.row["stateQuantity"]
                        : ""
                    }
                  />
                </StyledTableCellBody>
              ) : (
                <StyledTableCellBody align="center">
                  {Number(props.row["stateQuantity"])}
                </StyledTableCellBody>
              )
            }
          </>
        ) : (
          <>
            {
              // change the state quantity if it's under editing}
              props.edit === true &&
              (props.data["venueFormStatus"] === "Available" ||
                props.data["venueFormStatus"] === "Submitted") ? (
                <StyledTableCellBody align="center">
                  <input
                    type="number"
                    min="0"
                    style={{ width: 50 }}
                    data-rebateid={props.row["rebateId"]}
                    onChange={(e) =>
                      stores.member.updateVenueQuantityByRebateId(
                        props.row["rebateId"],
                        e.target.value,
                        "venueQuantity",
                      )
                    }
                    value={
                      props.row["venueQuantity"]
                        ? props.row["venueQuantity"]
                        : ""
                    }
                  />
                </StyledTableCellBody>
              ) : (
                <StyledTableCellBody align="center">
                  {Number(props.row["venueQuantity"])}
                </StyledTableCellBody>
              )
            }
          </>
        )}
        <StyledTableCellBody align="center">
          ${(props.row.payable ? props.row.payable : 0).toFixed(2)}
        </StyledTableCellBody>
      </TableRow>
    </React.Fragment>
  );
});

export default RowNostra;
